import React from 'react';
import logo from './logo.svg';
import './App.css';
import {   AmityUiKitProvider, AmityUiKitSocial } from '@amityco/ui-kit-open-source';
import '@amityco/ui-kit-open-source/dist/index.css'
//"b0e8e85c3b88f4344c30db18505e16ded40d8fe6bb3d6878";
// "b0ebed5b3c8ea4604c32841852081481d15adeb3b933697a" - MB
const apiKey = "b0e8e85c3b88f4344c30db18505e16ded40d8fe6bb3d6878";
const userId = "Tom1";
const displayName = "";
const apiEndpoint = "";
const apiRegion = "eu";
const communityId = "";
function getUrlParams(): Record<string, string> {
  const searchParams = new URLSearchParams(window.location.search);
  const params: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

function App() {

  const params = getUrlParams();

  return (
    <div className="">

      <AmityUiKitProvider
        key={params["userId"]}
        apiKey={apiKey}
        userId={params["userId"]}
        displayName={displayName} 
        apiRegion={apiRegion}
      >
        <div style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100dvh",
            }}>
        <AmityUiKitSocial />

        </div>

      </AmityUiKitProvider>
    </div>
  );
}

export default App;
